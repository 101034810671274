import { useState } from "react";
import reactStringReplace from "react-string-replace";
import "./card.css";
import external from "./external.svg";

type quote = {
    id: string;
    author: number;
    content: string;
    timestamp: string;
    channelId: string;
    guildId: string;
    addedBy: string;
    attachments?: string[];
};
export const Card = (props: {
    quote: quote;
    users: { [key: string]: any };
    channels: { [key: string]: any };
    ckey: number;
    isSingle: boolean;
    admin: boolean;
    token: string;
}) => {
    const [del, setDel] = useState<boolean>(false);
    const [deleted, setDeleted] = useState<boolean>(false);

    const userRex = /<@(\d.+?)>/gm;
    const channelRex = /<#(\d.+?)>/gm;
    const asteriskRex = /\*(.+?)\*/gm;
    const underlineRex = /\_(.+?)\_/gm;
    const newlineRex = /(\n)/gm;
    const gifRex = /(https:\/\/tenor\.com.+?\-\d.*)/gm;

    // console.groupCollapsed("card");
    // console.log("pre:", props.quote.content);
    let content = reactStringReplace(props.quote.content, userRex, (match, i) => {
        const uName = props.users[props.quote.author]?.user_nick
            ? props.users[props.quote.author]?.user_nick
            : props.users[props.quote.author]?.user_alias;
        return (
            <span key={`usr-${i}`} className="mention">
                @{uName}
            </span>
        );
    });
    content = reactStringReplace(content, newlineRex, (match, i) => {
        return <br key={`br-${i}`} />;
    });
    content = reactStringReplace(content, channelRex, (match, i) => {
        return (
            <span key={`channel-${i}`} className="mention">
                #{props.channels[match]}
            </span>
        );
    });
    content = reactStringReplace(content, asteriskRex, (match, i) => {
        return (
            <span key={`ast-${i}`} className="italic" style={{ fontStyle: "italic" }}>
                {match}
            </span>
        );
    });
    content = reactStringReplace(content, underlineRex, (match, i) => {
        return (
            <span key={`und-${i}-${match}`} className="italic" style={{ fontStyle: "italic" }}>
                {match}
            </span>
        );
    });

    content = reactStringReplace(content, gifRex, (match, i) => {
        return (
            <>
                <br />
                <img className="gif" key={`gif-${i}`} src={`${match}.gif`}></img>
            </>
        );
    });

    const handleDelete = async (id: string) => {
        if (del) {
            const req = await fetch(`/.netlify/functions/api-proxy/deleteQuote/${id}/${props.token}`);
            const json = await req.json();
            console.log(json);
            setDeleted(true);
        } else {
            setDel(true);
        }
    };
    let delConfirmStyle = {};
    if (del) {
        delConfirmStyle = {
            background: "#9611f3",
            padding: ".8rem",
            fontSize: "16px",
            fontWeight: "bold",
        };
    }
    // console.log("underline:", content);
    // console.groupEnd();
    const attachments = props.quote?.attachments?.length ? props.quote.attachments : false;
    const time = new Date(parseInt(props.quote.timestamp)).toISOString().split(".")[0].replace("T", " @ ");
    let aName = "";
    if (props.users && props.quote.addedBy) {
        if (props.users[props.quote.addedBy]?.user_nick) {
            aName = props.users[props.quote.addedBy].user_nick;
        } else if (props.users[props.quote.addedBy]?.user_alias) {
            aName = props.users[props.quote.addedBy].user_alias;
        }
    }
    let uName = "";
    if (props.users[props.quote.author]?.user_nick) {
        uName = props.users[props.quote.author].user_nick;
    } else {
        uName = props.users[props.quote.author]?.user_alias || "n/a";
    }
    if (!props.users[props.quote.author]) {
        console.log("Missing:", [props.quote.author], props.ckey);
    }
    console.log(props.ckey, content);
    return (
        <>
            {!deleted && (
                <div className="card" key={props.ckey}>
                    {!props.isSingle && (
                        <img
                            key={`${props.ckey}-img`}
                            alt=""
                            className="external"
                            src={external}
                            onClick={() => {
                                document.location.href = `/?id=${props.quote.id}`;
                            }}></img>
                    )}
                    <p className="content">
                        <span className="author">@{uName} </span>
                        {/* {content.map((c) => (
                                <span>{c}</span>
                            ))} */}
                        {content}
                        {attachments && attachments.map((att, i) => <img key={`img-${i}`} src={att} alt="" />)}
                    </p>
                    <div className="details">
                        <div className="footer">
                            <span className="timestamp">
                                Added by <span className="addedBy">{aName}</span>
                                <br />
                                {time} in{" "}
                            </span>
                            <span className="channel">#{props.channels[props.quote.channelId]}</span>
                        </div>
                        <a
                            href={`https://discordapp.com/channels/${props.quote.guildId}/${props.quote.channelId}/${props.quote.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="link">
                            original message &raquo;
                        </a>
                    </div>
                    {props.admin && (
                        <div className="admin">
                            <div
                                className="button"
                                style={delConfirmStyle}
                                onClick={() => {
                                    handleDelete(props.quote.id);
                                }}>
                                {del ? "really!?" : "delete"}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
